<template>
  <div>
    <defaultFieldTypes
      :fieldAttributes="{ ...fieldAttributes, options: options }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :checkReadOnly="checkReadOnly"
      :value="value"
      :valueLine="valueLine"
      :hideDetails="hideDetails"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: [
    "result",
    "fieldAttributes",
    "value",
    "field",
    "checkReadOnly",
    "valueLine",
    "hideDetails",
  ],
   created() {
    this.$nextTick(function () {
      this.$emit("revalidate");
    });
  },
  computed: {
    options() {
      let options = [];
      let metric = this.result.mainContent.valueLine.metric;
      console.log(this.result.mainContent.fields);
      if (
        typeof metric != "undefined" &&
        typeof this.result.mainContent.fields.metric.info[metric] != "undefined" &&
        typeof this.result.mainContent.fields.metric.info[metric].tags !="undefined" &&
        this.result.mainContent.fields.metric.info[metric].tags!=null &&
        this.result.mainContent.fields.metric.info[metric].tags!=''
      ) {
        options = Object.keys(
          this.result.mainContent.fields.metric.info[metric].tags
        );
      }
      this.result.valueArray.forEach((element) => {
        if (element.tag != this.value) {
          options = options.filter((item) => item !== element.tag);
        }
      });
      console.log(options);
      return options;
    },
  },
};
</script>